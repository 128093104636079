<authLayout>
  <div class="card card-plain mt-8">
      <div class="card-header pb-0 text-left bg-transparent">
          <h3 class="font-weight-bolder text-primary text-gradient">Welcome</h3>
          <p
              class="mb-0">
              Enter your email and password to sign up.</p>
      </div>
      <div class="card-body">
          <form role="form text-left">
              <div class="mb-3">
                <input type="username" class="form-control" placeholder="Username" aria-label="Username" v-model="username">
              </div>
              <div class="mb-3">
                  <input type="email" class="form-control" placeholder="Email" aria-label="Email" v-model="email">
              </div>
              <div class="mb-3">
                  <input type="password" class="form-control" placeholder="Password" aria-label="Password" v-model="password">
              </div>
              <div class="text-center">
                  <button type="button" class="btn bg-gradient-primary w-100 my-4 mb-2" @click="signup">Register</button>
              </div>
          </form>
      </div>
      <div class="card-footer text-center pt-0 px-lg-2 px-1">
          <p class="mb-4 text-sm mx-auto">
            Already have an account?
            <a href="./sign-up" class="text-dark font-weight-bold">Log in</a>
          </p>
      </div>
  </div>
</authLayout>